.Toolbar {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom right, #3b1e1e, #532f2f);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  color: rgb(212, 212, 212);
}

.Toolbar nav {
  height: 100%;
}

@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}