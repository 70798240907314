* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    background: linear-gradient(to bottom right, #D7D5D8, #aeabaf );
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    height: 100vh;
}
.Layout_Content__P52t9 {
    margin-top: 72px;
}
.Toolbar_Toolbar__3ltML {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom right, #3b1e1e, #532f2f);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  color: rgb(212, 212, 212);
}

.Toolbar_Toolbar__3ltML nav {
  height: 100%;
}

@media (max-width: 499px) {
    .Toolbar_DesktopOnly__3G2or {
        display: none;
    }
}
.Logo_Logo__2mA37 {
    font-family: 'Domine', serif;
    font-size: xx-large;
}
.GoogleAuth_btn__MaSG3, .GoogleAuth_btn__MaSG3:visited, input[type=submit] {
    display: inline-block;
    padding: 10px 30px;
    font-family: 'Domine', serif;
    font-weight: 300;
    text-decoration: none;
    border-radius: 200px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.GoogleAuth_btnFull__YmvMN, .GoogleAuth_btn-full__1Cf4T:visited, input[type=submit] {
background: linear-gradient(#254732, #12291b);
    border: 1px solid #12291b;
    color: #fff;
}

.GoogleAuth_btn__MaSG3:hover, .GoogleAuth_btn__MaSG3:active, input[type=submit]:hover, input[type=submit]:active {
    background: linear-gradient(#1c3626, #0c1b12);
}

.GoogleAuth_btnFull__YmvMN:hover, .GoogleAuth_btn-full__1Cf4T:active {
    border: 1px solid #1C3A27;
}
.NavigationItems_NavigationItems__1VhGt {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__1VhGt {
        flex-flow: row;
    }
}
